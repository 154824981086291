import { createGlobalState } from '@vueuse/core';
import type { TemplatePageSection } from '~/src/shared/lib';

const useStore = createGlobalState(() => {
	const education = ref<Array<TemplatePageSection>>([]);
	const documents = ref<Array<TemplatePageSection>>([]);
	const instructions = ref<Array<TemplatePageSection>>([]);

	return {
		education,
		documents,
		instructions,
	};
});

export const saveEducation = (data: Array<TemplatePageSection>) => {
	const store = useStore();
	store.education.value = data;
};

export const useEducation = () => {
	const store = useStore();
	return computed(() => store.education.value);
};

export const saveDocuments = (data: Array<TemplatePageSection>) => {
	const store = useStore();
	store.documents.value = data;
};

export const useDocuments = () => {
	const store = useStore();
	return computed(() => store.documents.value);
};

export const saveInstructions = (data: Array<TemplatePageSection>) => {
	const store = useStore();
	store.instructions.value = data;
};

export const useInstructions = () => {
	const store = useStore();
	return computed(() => store.instructions.value);
};
